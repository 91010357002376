import ETH from './eth.png'
import BNB from './bnb.png'
import opBNB from './opbnb.svg'
import BASE from './baseEth.svg'
import HBIT from './hbit.png'
import MATIC from './matic.png'
import AVAX from './avax.png'
import ARBITRUM from './arbitrum.png'
import SHIBARIUM from './shibarium.png'
export const CURRENCY = {
  ETH,
  BNB,
  opBNB,
  BASE,
  HBIT,
  MATIC,
  AVAX,
  ARBITRUM,
  // ------------

  1: ETH,
  3: ETH,
  4: ETH,
  5: ETH,
  42: ETH,
  56: BNB,
  97: BNB,
  204: opBNB,
  8453: BASE,
  11119: HBIT,
  137: MATIC,
  43114: AVAX,
  42161: ARBITRUM,
  109: SHIBARIUM,
}
