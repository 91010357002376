export const networks = {
  1: {
    name: "Ethereum",
    rpc: 'https://rpc.ankr.com/eth',
    chainId: 1,
    explorer: "https://etherscan.io",
    color: "#627EEA",
    multicall: "0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441",
    ENSRegistry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    fromBlock: 16669000,
    baseCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH"
    },
    wrappedToken: {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      name: "Wrapped Etherer",
      symbol: "WETH"
    }
  },
  5: {
    name: "Görli",
    rpc: "https://rpc.ankr.com/eth_goerli",
    chainId: 5,
    explorer: "https://goerli.etherscan.io",
    color: "#f6c343",
    storage: "0x171a664f12672a61E4e948BC7Fd38eB34b64a15b",
    multicall: "0xFD4e092227e876dD8F2d036FA8fEB23F8A7F94ca",
    ENSRegistry: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
    fromBlock: 8385000,
    baseCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH"
    },
    wrappedToken: {
      address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      name: "Wrapped Ether",
      symbol: "WETH"
    }
  },
  56: {
    name: "BSC",
    rpc: "https://bscrpc.com/",
    chainId: 56,
    explorer: "https://bscscan.com",
    color: "#CC9B00",
    storage: "0xa7472f384339D37EfE505a1A71619212495A973A",
    multicall: "0x41263cBA59EB80dC200F3E2544eda4ed6A90E76C",
    fromBlock: 25825000,
    baseCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB"
    },
    wrappedToken: {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      name: "Wrapped BNB",
      symbol: "WBNB"
    }
  },
  204: {
    name: "opBNB",
    rpc: "https://opbnb-mainnet-rpc.bnbchain.org",
    chainId: 204,
    explorer: "https://mainnet.opbnbscan.com",
    color: "#CC9B00",
    multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
    fromBlock: 512881,
    baseCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB"
    },
    wrappedToken: {
      address: "0x4200000000000000000000000000000000000006",
      name: "Wrapped BNB",
      symbol: "WBNB"
    }
  },
  11119: {
    name: "HBIT",
    rpc: "https://rpc.hashbit.org",
    chainId: 11119,
    explorer: "https://explorer.hashbit.org",
    color: "#072D4C",
    multicall: "0xd09E2a7f67ab3BB7f79042aACA2202E60890EEBa",
    fromBlock: 2618007,
    baseCurrency: {
      decimals: 18,
      name: "HBIT",
      symbol: "HBIT"
    },
    wrappedToken: {
      address: "0x87599C8AE56bCF5115c3074151fC33719ef3c74E",
      name: "Wrapped HBIT",
      symbol: "WHBIT"
    }
  },
  137: {
    name: "Polygon",
    rpc: "https://polygon-rpc.com/",
    chainId: 137,
    explorer: "https://polygonscan.com/",
    color: "#8247E5",
    multicall: "0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507",
    fromBlock: 11773052,
    baseCurrency: {
      decimals: 18,
      name: "Matic",
      symbol: "MATIC"
    },
    wrappedToken: {
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      name: "Wrapped Matic",
      symbol: "WMATIC"
    }
  },
  43114: {
    name: "Avalanche",
    rpc: "https://api.avax.network/ext/bc/C/rpc",
    chainId: 43114,
    explorer: "https://snowtrace.io",
    color: "#D64F4A",
    multicall: "0x04ad4Ce6015141F6f582A7451Cb7CD6866609298",
    fromBlock: 10331655,
    baseCurrency: {
      decimals: 18,
      name: "AVAX",
      symbol: "AVAX"
    },
    wrappedToken: {
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      name: "Wrapped AVAX",
      symbol: "WAVAX"
    }
  },
  42161: {
    name: "Arbitrum",
    rpc: "https://arb1.arbitrum.io/rpc",
    chainId: 42161,
    explorer: "https://arbiscan.io/",
    color: "#e7eaf3",
    multicall: "0x842eC2c7D803033Edf55E478F461FC547Bc54EB2",
    fromBlock: 821923,
    baseCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH"
    },
    wrappedToken: {
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      name: "Wrapped ETH",
      symbol: "WETH"
    }
  },
  109: {
    name: "Shibarium",
    rpc: "https://rpc.shibrpc.com",
    chainId: 109,
    explorer: "https://shibariumscan.io",
    color: "#e7eaf3",
    multicall: "0x562059183Ad4F916BAAACDFbEf93a9c144Fe8328",
    fromBlock: 265205,
    baseCurrency: {
      decimals: 18,
      name: "BONE",
      symbol: "BONE"
    },
    wrappedToken: {
      address: "0xC76F4c819D820369Fb2d7C1531aB3Bb18e6fE8d8",
      name: "Wrapped BONE",
      symbol: "WBONE"
    }
  },

  8453: {
    name: "BASE",
    rpc: "https://mainnet.base.org",
    chainId: 8453,
    explorer: "https://basescan.org",
    color: "#155BFD",
    multicall: "0xEF65BF9e225A4B606aa5C46f944De35f566e58e4",
    fromBlock: 2111390,
    baseCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH"
    },
    wrappedToken: {
      address: "0x4200000000000000000000000000000000000006",
      name: "Wrapped Ether",
      symbol: "WETH"
    }
  },  
}

export const chainRouter = {
  1: [
    {
      name: "DOCSWAP",
      FACTORY: "0x0f667E1c023c54EF88f9a55B16be601a423318fF",
      WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      ROUTER: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
    },
	],
  5: [
    {
      name: "Uniswap",
      FACTORY: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
      WETH: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
      ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    },
  ],
  43114: [
    {
      name: "DOCSWAP",
      FACTORY: "0x0f667E1c023c54EF88f9a55B16be601a423318fF",
      WETH: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      ROUTER: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
    },
  ],
  42161: [
    {
      name: "DOCSWAP",
      FACTORY: "0x0f667E1c023c54EF88f9a55B16be601a423318fF",
      WETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      ROUTER: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
    },
  ],
  109: [
    {
      name: "DOCSWAP",
      FACTORY: "0x0f667E1c023c54EF88f9a55B16be601a423318fF",
      WETH: "0xC76F4c819D820369Fb2d7C1531aB3Bb18e6fE8d8",
      ROUTER: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
    },
  ],

  56: [
    {
      name: "DOCSWAP",
      FACTORY: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
      WETH: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      ROUTER: "0xf8AF117a61993f02F70884f0507f87B653d4bBA4",
    },
  ],
  204: [
    {
      name: "DOCSWAP",
      FACTORY: "0x0f667E1c023c54EF88f9a55B16be601a423318fF",
      WETH: "0x4200000000000000000000000000000000000006",
      ROUTER: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
    },
  ],
  8453: [
    {
      name: "DOCSWAP",
      FACTORY: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
      WETH: "0x87599C8AE56bCF5115c3074151fC33719ef3c74E",
      ROUTER: "0xf8AF117a61993f02F70884f0507f87B653d4bBA4",
    },
  ],
  11119: [
    {
      name: "DOCSWAP",
      FACTORY: "0x0f667E1c023c54EF88f9a55B16be601a423318fF",
      WETH: "0x4200000000000000000000000000000000000006",
      ROUTER: "0x9904C6d92Ed455292F88b58665Bc95b7019343C2",
    },
  ],

  97: [
    {
      name: "PancakeSwap",
      FACTORY: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
      WETH: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      ROUTER: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    },
  ],
};


export default {
  chainRouter,
  networks,
}
